
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getEventPostList } from '@/api/eventPost';
import InfiniteLoading from 'vue-infinite-loading';

@Component({
  components: {
    InfiniteLoading,
  },
})

export default class extends Vue {
  mounted() {
    this.getEventList();
  }

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private listQuery = {
    page: 0,
    size: 10,
    searchType: 'title',
    searchValue: '',
    sort: 'status',
  }

  private isNoMore = false;

  private loading = true;

  private eventList = [];

  private filterSortDrawer = false;

  private sortList = [
    { label: '등록일순', value: 'createDate' },
    { label: '마감일순', value: 'dueDate' },
  ];

  private getEventList() {
    this.loading = true;
    getEventPostList(this.listQuery).then((res) => {
      this.loading = false;
      this.eventList = this.eventList.concat(res.data.content);
      this.isNoMore = res.data.last;
    }).catch(() => {
      this.$message.error('이벤트를 불러오는데 실패했습니다.');
      this.loading = false;
    });
  }

  private getMoreEventList($state: any) {
    if (!this.isNoMore) {
      this.listQuery.page += 1;
      this.loading = true;
      getEventPostList(this.listQuery).then((res) => {
        this.loading = false;
        this.eventList = this.eventList.concat(res.data.content);
        if (res.data.content.length > 0) {
          $state.loaded();
        } else {
          this.isNoMore = true;
          $state.complete();
        }
      }).catch((error) => {
        this.$message.error('이벤트를 불러오는데 실패했습니다.');
        this.loading = false;
        $state.complete();
      });
    }
  }

  private handleClickSort(sort: string) {
    this.eventList = [];
    this.filterSortDrawer = false;
    this.listQuery.sort = sort;
    this.listQuery.page = 0;
    this.isNoMore = false;
    this.getEventList();
  }

  private handleSearch() {
    this.eventList = [];
    this.listQuery.page = 0;
    this.isNoMore = false;
    this.getEventList();
  }

  private getSortLabel() {
    let label = '';
    const idx = this.sortList.findIndex((sort: any) => sort.value === this.listQuery.sort);
    if (idx > -1) label = this.sortList[idx].label;
    return label;
  }
}
